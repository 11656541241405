export type SiteConfig = typeof siteConfig
export const siteConfig = {
    name: "Sprunki Infected",
    slogan: "slogan",
    icon: "/favicon.ico",
    appleIcon: "/apple-touch-icon.png",
    gameIframe: "https://html-classic.itch.zone/html/11700918/index.html",
    domain: "sprunkiinfected.online",
    gaId: "G-P92FNK02R4",
    navbarItems: [
        {
            title: "title",
             href: "/",
        }
    ],
}
